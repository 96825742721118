<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h3>ข้อมูลตัวแทนผู้ขาย</h3>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <!-- <v-col class="text-right">
            <v-btn @click="goToCreate()">สร้าง Reference Code</v-btn>
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersCategory"
            :items="items"
            :search="search"
            :items-per-page="-1"
            class="elevation-1"
          >
            <template v-slot:[`item.activeFlag`]="{ item }">
              <v-icon v-if="item.activeFlag == true" class="green" dark
                >mdi-check
              </v-icon>
              <v-icon v-if="item.activeFlag == false" class="red" dark
                >mdi-close</v-icon
              >
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <!-- <v-icon class="mr-2" @click="editUser(item)" color="green"
                  >mdi-cash-multiple</v-icon
                > -->

                <v-icon class="mr-2" @click="viewUser(item)">mdi-pencil</v-icon>
                <!-- <v-icon class="mr-2" @click="UpdateCate(item)" class="mx-2"
                  >mdi-pencil</v-icon
                > -->
                <!-- <v-icon class="mr-2" @click="DeleteUser(item)"
                  >mdi-delete</v-icon
                > -->

                <!-- <v-col cols="4" class="mx-0 px-1"
              ><v-btn @click="GetQuestions(item)" color="#833133" dark
                ><v-icon class="mr-2">mdi-magnify</v-icon></v-btn
              ></v-col
            > -->
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      headersCategory: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "ชื่อ", value: "firstName", align: "center" },
        { text: "จำนวนโควต้า", value: "clickCount", align: "center" },
        // { text: "อีเมล", value: "email", align: "center" },
        // { text: "หมายเลขโทรศัพท์", value: "tel", align: "center" },
        // { text: "อนุมัติ", value: "activeFlag", align: "center" },
        // { text: "VIP", value: "member", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center", width: "150" },
      ],
      items: [
        // { category: "promotion", description: "สินค้าจัดโปร" },
        // { category: "discount items", description: "สินค้าลดราคา" },
        // { category: "Shock price", description: "สินค้าราคาถูก" },
        // { category: "สินค้ามาใหม่", description: "สินค้ามาใหม่" },
      ],
      search: "",
      count: 0,
    };
  },
  created() {
    this.getAllUsers();
  },
  methods: {
    async getAllUsers() {
      var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/agents`,
        auth
        // 'http://localhost:8081/categories/'
      );
      console.log("users", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
    },
    viewUser(val) {
      localStorage.setItem("dealerdata", Encode.encode(val));
      //   console.log(val);
      this.$router.push("viewDealer");
    },
    editUser(val) {
      localStorage.setItem("dealerdata", Encode.encode(val));
      //   console.log(val);
      this.$router.push("editDealer");
    },
    async DeleteUser(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        // cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("DDLotAdmin"))
          );
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/agents/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllUsers();
        }
      });
    },
    goToCreate() {
      this.$router.push("GenRefCode");
    },
  },
};
</script>
